import { CenteredDiv, RegularButton, PrimaryButton } from '@wavetronix/common-components'
import ExamsApi from '../api/ExamsApi'
import GatekeeperApi from '../api/GatekeeperApi.js'
import { useMsal } from '@azure/msal-react'
import queryString from 'query-string'
import { useState, useEffect, useCallback, useRef } from 'react'
import EmailApi from '../api/EmailApi.js'
import CertificateModal from './CertificateModal.jsx'
import ExamReview from './ExamReview.jsx'
import { Card } from '@mui/material'

const PASS_THRESHOLD = 85
let emailSent = false
let user = {}
let issuer = {}

export default function SubmittedPage() {
  const [modalState, setModalState] = useState(false)
  const { instance, accounts } = useMsal()
  const [record, setRecord] = useState(null)
  const [currentSubmission, setCurrentSubmission] = useState({})
  const [loading, setLoading] = useState(true)
  const [certificateInfo, setCertificateInfo] = useState({})
  let urlObj = queryString.parseUrl(window.location.href)
  let examID = urlObj.query.id
  let issuerID = urlObj.query.issuer
  let bestScore = useRef(0)
  let retakeSlug = `/?id=${examID}&issuer=${issuerID}`
  const loadData = useCallback(
    async function () {
      user = await GatekeeperApi.getMe(instance, accounts)
      issuer = await GatekeeperApi.getUser(issuerID)
      const record = await ExamsApi.getUserExamRecord(instance, accounts, user.id, examID)
      if (record && record.length > 0) {
        setRecord(record[0])
      } else setRecord(null)
    },
    [instance, accounts, examID, issuerID]
  )

  const getCurrent = useCallback(function (data) {
    if (data !== null) {
      let sorted = data.submissions.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })[0]
      setCurrentSubmission(sorted)
      let scores = data.submissions.map(submission => submission.grade.percentage)
      bestScore.current = Math.max(...scores)
      let date = new Date(sorted.date)
      setCertificateInfo({
        user: `${user.givenName} ${user.surname}`,
        exam: `${data.examName}`,
        issuer: `${issuer.givenName} ${issuer.surname}`,
        issueDate: date.toLocaleDateString('en-US'),
        expireDate: new Date(date.setFullYear(date.getFullYear() + 3)).toLocaleDateString('en-US')
      })
    }
  }, [])

  async function sendEmailConditional(currentSubmission, bestScore) {
    if (record.notified === false && emailSent === false) {
      if (currentSubmission.grade.percentage >= PASS_THRESHOLD) {
        EmailApi.passExam(instance, accounts, {
          issuerEmail: record.issuerEmail,
          submitterEmail: `${user.email}`,
          submitterName: `${user.givenName} ${user.surname}`,
          examName: `${record.examName}`
        })
        emailSent = true
        let newRecord = {
          attemptsRemaining: record.attemptsRemaining,
          submissions: record.submissions,
          userId: record.userId,
          examId: record.examId,
          id: record.id,
          examName: record.examName,
          notified: true,
          issuerEmail: record.issuerEmail,
          issuerId: record.issuerId
        }
        ExamsApi.submitExam(instance, accounts, `/${record.id}`, newRecord)
      } else if (record.attemptsRemaining > 0 && bestScore < PASS_THRESHOLD) {
        EmailApi.failExam(instance, accounts, {
          issuerEmail: record.issuerEmail,
          submitterEmail: `${user.email}`,
          submitterName: `${user.givenName} ${user.surname}`,
          examName: `${record.examName}`,
          score: `${currentSubmission.grade.percentage}`,
          attemptsRemaining: `${record.attemptsRemaining}`
        })
        emailSent = true
        let newRecord = {
          attemptsRemaining: record.attemptsRemaining,
          submissions: record.submissions,
          userId: record.userId,
          examId: record.examId,
          id: record.id,
          examName: record.examName,
          notified: true,
          issuerEmail: record.issuerEmail,
          issuerId: record.issuerId
        }
        ExamsApi.submitExam(instance, accounts, `/${record.id}`, newRecord)
      } else if (record.attemptsRemaining === 0 && bestScore < PASS_THRESHOLD) {
        EmailApi.lockExam(instance, accounts, {
          issuerEmail: record.issuerEmail,
          submitterEmail: `${user.email}`,
          submitterName: `${user.givenName} ${user.surname}`,
          examName: `${record.examName}`
        })
        emailSent = true
        let newRecord = {
          attemptsRemaining: record.attemptsRemaining,
          submissions: record.submissions,
          userId: record.userId,
          examId: record.examId,
          id: record.id,
          examName: record.examName,
          notified: true,
          issuerEmail: record.issuerEmail,
          issuerId: record.issuerId
        }
        ExamsApi.submitExam(instance, accounts, `/${record.id}`, newRecord)
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    getCurrent(record)
  }, [getCurrent, record])

  useEffect(() => {
    if (currentSubmission.grade) {
      setLoading(false)
    }
  }, [currentSubmission])

  if (loading === false) {
    if (record === null)
      return (
        <>
          <h1>Loading your results...</h1>
          If this screen persists, it's possible you've encountered an internal server error. Please contact a member of the
          Gojira team.
        </>
      )
    else {
      if (Object.values(certificateInfo).length > 0) {
        sendEmailConditional(currentSubmission, bestScore.current)
      }

      return (
        <>
          <CertificateModal
            open={modalState}
            onClose={() => setModalState(false)}
            certificateInfo={certificateInfo}
          ></CertificateModal>
          <CenteredDiv>
            <h1>
              You finished the exam! Your score: {currentSubmission.grade ? currentSubmission.grade.percentage : 'loading...'}%
            </h1>
          </CenteredDiv>
          <CenteredDiv sx={{ width: '60%', margin: 'auto' }}>
            <div style={{ width: 'auto' }}>
              <Card sx={{ paddingLeft: '32px', paddingRight: '32px' }}>
                <p>
                  Best Score: <span style={{ fontWeight: 'bold' }}>{bestScore.current}%</span>.
                </p>
                <p>
                  Attempts Remaining: <span style={{ fontWeight: 'bold' }}>{record.attemptsRemaining}</span>
                </p>
              </Card>
            </div>
            <div style={{ width: '50%', paddingLeft: '32px', paddingRight: '32px' }}>
              <p>
                {' '}
                Your best score will be saved. Please contact your Wavetronix technical trainer if you need additional attempts.
              </p>
            </div>
          </CenteredDiv>{' '}
          <CenteredDiv sx={{ margin: '16px' }}>
            {bestScore.current > PASS_THRESHOLD ? (
              <PrimaryButton id='openCertificateModalButton' onClick={() => setModalState(true)}>
                Get certificate
              </PrimaryButton>
            ) : record.attemptsRemaining > 0 ? (
              <RegularButton id='retakeExamButton' href={retakeSlug}>
                Retake
              </RegularButton>
            ) : (
              <></>
            )}
          </CenteredDiv>
          <ExamReview examId={examID} examName={record.examName} retakeSlug={retakeSlug}></ExamReview>
        </>
      )
    }
  }
}
